<template>
  <div id="ai-services_nlp_reading-comprehension">
    <service-template
      :class="{ 'nlp-example-rtl': currentLanguage === $consts.lang.AR }"
      :run="run"
      :run-success="runSuccess"
      :run-error="runError"
      :run-disabled="runDisabled"
      :loading="loading"
      :icon="require('@/assets/images/icons/services/header/RCH.svg')"
      service="nlp"
    >
      <template v-slot:header>
        {{ $t('services.readingComprehension.header') }}
      </template>

      <template v-slot:left>
        <input-language
          :step="1"
          :languages="availableLanguages"
          :current-language.sync="currentLanguage"
          :loading="loading"
        />
        <example-text
          :step="2"
          :items="textExamples"
          :current-example.sync="currentExample"
          :example-text.sync="exampleText"
          :loading="loading"
        />

        <div class="question-text">
          <h5 class="step-title">
            <span class="step-title__primary-text">{{
              $t('services.step', [3])
            }}</span>
            <span class="step-title__secondary-text">
              {{ $t('services.exampleQuestion.title') }}</span>
          </h5>

          <el-select
            v-model="currentQuestion"
            :disabled="loading || disableQuestionSelection"
            :placeholder="$t(`placeholder['select question']`)"
            :popper-class="currentLanguage === $consts.lang.AR ? 'rtl':''"
            @input="onSelectQuestion"
          >
            <el-option
              v-for="(item, index) in exampleQuestions"
              :key="index"
              :value="index"
              :label="item"
            />
          </el-select>

          <template v-if="isAuthenticated()">
            <h5 class="step-title user-question-title">
              <span class="step-title__primary-text">
                {{ $t('services.exampleQuestion.subtitle') }}</span>
            </h5>

            <el-input
              v-model="currentQuestionText"
              :disabled="loading"
              dir="auto"
              :placeholder="$t('services.exampleQuestion.inputPlaceholder')"
            />
          </template>
        </div>
      </template>
      <template v-slot:right>
        <generated-result
          :step="4"
          :service-name="$t('services.readingComprehension.header')"
          :loading="loading"
        >
          <div
            v-if="result && result.length"
            class="result"
          >
            {{ result }}
          </div>
        </generated-result>
      </template>
    </service-template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ServiceTemplate from '@/views/pages/AIServices/components/ServiceTemplate/Index.vue';
import InputLanguage from '@/views/pages/AIServices/components/InputLanguage/Index.vue';
import GeneratedResult from '@/views/pages/AIServices/components/GeneratedResult/Index.vue';
import ExampleText from '@/views/pages/AIServices/components/ExampleText/Index.vue';
import { constants } from '@/libs/constants';

export default {
  name: 'ReadingComprehension',
  components: {
    ExampleText,
    GeneratedResult,
    InputLanguage,
    ServiceTemplate,
  },
  data: () => ({
    availableLanguages: [constants.lang.EN, constants.lang.AR],
    currentLanguage: constants.lang.EN,
    currentExample: null,
    examples: [],
    exampleText: '',
    currentQuestionText: '',
    currentQuestion: null,
    result: null,
    disableQuestionSelection: false,
    loading: false,
  }),
  computed: {
    ...mapGetters(['isAuthenticated']),
    textExamples() {
      return (
        this.examples[this.currentLanguage] || []
      ).map(({ example: value }, id) => ({ id, value }));
    },

    exampleQuestions() {
      try {
        return this.examples[this.currentLanguage][this.currentExample]
          .questions;
      } catch (error) {
        return [];
      }
    },

    runDisabled() {
      return (
        !this.exampleText || !this.currentLanguage || !this.currentQuestionText
      );
    },
  },

  watch: {
    currentLanguage() {
      this.currentExample = 0;
      this.resetQuestions();
      this.result = null;
    },

    currentExample() {
      this.resetQuestions();

      this.result = null;
    },
    exampleText() {
      this.result = null;
      // eslint-disable-next-line eqeqeq
      if (this.exampleText != this.textExamples[this.currentExample].value) {
        if (this.currentQuestion !== null) {
          this.currentQuestion = null;
          this.currentQuestionText = '';
          this.disableQuestionSelection = true;
        }
      } else {
        this.disableQuestionSelection = false;
      }
    },
  },

  async created() {
    this.examples = await import('@/../public/examples/NLP/readingComprehension.json');
    this.currentExample = 0;
  },

  methods: {
    async run() {
      this.loading = true;
      return this.$api.nlp.getReadingComprehension({
        lang: this.currentLanguage,
        text: this.exampleText,
        questions: [this.currentQuestionText],
      });
    },
    runSuccess({ data: { answers, answer_string: answerString } }) {
      this.loading = false;
      this.result = (answers && answers.toString()) || answerString || this.$t('error[\'Oops Sorry! Couldn\'t find anything\']');
    },

    onSelectQuestion(newValue) {
      this.currentQuestionText = this.exampleQuestions[newValue];
    },

    resetQuestions() {
      this.currentQuestion = 0;
      this.currentQuestionText = this.exampleQuestions[0];
    },

    runError() {
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#ai-services_nlp_reading-comprehension {
  background: $background-secondary;

  .question-text {
    margin-top: 0.29rem;
  }

  .step-title {
    @include steps-title();
  }

  .user-question-title {
    margin-top: 0.16rem;
  }

  /deep/ .generated-result__preview {
    max-height: 6.94rem;
  }

  @include nlp-el-select();
}
</style>
